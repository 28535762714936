<template>
  <div>
    <el-form :model="form" ref="form" :hide-required-asterisk="true">
      <el-form-item
        :label="$t('Office Name')"
        prop="office_name"
        :rules="[{ required: true, message: 'The field is required' }]"
      >
        <el-input type="text" v-model="form.office_name"></el-input>
      </el-form-item>

      <el-form-item :label="$t('Oficce DBA')" prop="dba">
        <el-input type="text" v-model="form.dba"></el-input>
      </el-form-item>

      <el-form-item :label="$t('Office EIN')" prop="ein">
        <el-input
          type="text"
          v-model="form.ein"
          placeholder="e.g 25-45465ST"
          v-mask="'##-NNNNNNN'"
          >></el-input
        >
      </el-form-item>

      <div class="row">
        <div class="col-6">
          <el-form-item :label="$t('Address')" prop="address">
            <el-input
              type="text"
              v-model="form.address"
              placeholder="e.g Street 101"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="$t('City')" prop="city">
            <el-input
              type="text"
              v-model="form.city"
              placeholder="e.g Livingston"
            ></el-input>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item :label="$t('State')" prop="state">
            <v-select
              class="style-chooser"
              placeholder="Select state"
              :options="states"
              label="name"
              :reduce="(state) => state.id"
              v-model="form.state"
            ></v-select>
          </el-form-item>
        </div>

        <div class="col-6">
          <el-form-item
            :label="$t('office.zip')"
            prop="zip"
            :rules="[{ pattern: /^[0-9]{5}$/, message: 'Format zip invalid' }]"
          >
            <el-input
              type="text"
              v-model="form.zip"
              placeholder="e.g 12345"
            ></el-input>
          </el-form-item>
        </div>
      </div>

      <el-form-item :label="$t('Upload your logo')" prop="logo">
        <input type="file" v-on:change="onImageChange" class="el-input__inner">
      </el-form-item>

      <strong>Services:</strong><br /><br />

      <div>
        <multiselect
          v-model="value"
          :options="services"
          :multiple="true"
          :close-on-select="false"
          :clear-on-select="false"
          :preserve-search="true"
          placeholder="Select Services"
          label="name"
          track-by="id"
          :select-label="''"
          :deselect-label="''"
          @open="openBackgroundOpacity"
          @close="closeBackgroundOpacity"
        >
          <template slot="selection" slot-scope="{ values, search, isOpen }"
            ><span
              class="multiselect__single"
              v-if="values.length &amp;&amp; !isOpen"
              >{{ values.length }} options selected</span
            ></template
          >
        </multiselect>
      </div>

      <strong>Contact:</strong><br /><br />

      <el-form-item :label="$t('Office Manager')" prop="manager_name">
        <el-input type="text" v-model="form.manager_name"></el-input>
      </el-form-item>

      <el-form-item
        :label="$t('Office Email')"
        prop="email"
        :rules="[
          { required: true, message: 'The field is required' },
          { type: 'email', message: 'The format email invalid' },
        ]"
      >
        <el-input type="text" v-model="form.email"></el-input>
      </el-form-item>

      <div class="row">
        <div class="col-6">
          <el-form-item
            :label="$t('office.phone')"
            prop="phone"
            :rules="[
              {
                pattern: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                message: 'Format phone invalid',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="form.phone"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
            ></el-input>
          </el-form-item>
        </div>
        <div class="col-6">
          <el-form-item
            :label="$t('office.fax')"
            prop="fax"
            :rules="[
              {
                pattern: /^\([0-9]{3}\)\s[0-9]{3}-[0-9]{4}$/,
                message: 'Format phone invalid',
              },
            ]"
          >
            <el-input
              type="text"
              v-model="form.fax"
              placeholder="e.g (414) 639-1121"
              v-mask="'(###) ###-####'"
              >></el-input
            >
          </el-form-item>
        </div>

        <div class="col-md-12">
          <el-form-item
            :label="$t('Status')"
            prop="office_status"
            :rules="[{ required: true, message: 'Status is required' }]"
          >
            <el-select
              v-model="form.office_status"
              placeholder="Select Status"
              class="w-100"
            >
              <el-option
                v-for="item in status_select"
                :key="item.id"
                :label="item.name"
                :value="item.id"
              >
              </el-option>
            </el-select>
          </el-form-item>
        </div>
      </div>

      <el-button
        type="primary"
        @click="!data ? submitForm('form') : updateForm('form')"
        >Save Register</el-button
      >
    </el-form>
  </div>
</template>

<script>
import location from "@/services/api/location";
import office from "@/services/api/office";
import service from "@/services/api/service";
import Multiselect from "vue-multiselect";

export default {
  props: ["data"],
  data() {
    return {
      status_select: [
        { id: "1", name: "Active" },
        { id: "0", name: "Inactive" },
      ],
      cities: [],
      states: [],
      services: [],
      value: [],
      form: {
        office_name: null,
        manager_name: null,
        email: null,
        old_email: null,
        dba: null,
        ein: null,
        phone: null,
        fax: null,
        address: null,
        state: null,
        city: null,
        zip: null,
        image: null,
        office_status: null,
      },
    };
  },
  components: { Multiselect },
  mounted() {
    location.state().then((response) => {
      this.states = response;
    });
    service.get().then((response) => {
      this.services = response;
    });
    //
    if (this.data) {
      this.form.id = this.data.id;
      this.form.office_name = this.data.office_name;
      this.form.manager_name = this.data.manager_name;
      this.form.email = this.data.email;
      this.form.old_email = this.data.email;
      this.form.dba = this.data.dba;
      this.form.ein = this.data.ein;
      this.form.phone = this.data.phone;
      this.form.fax = this.data.fax;
      this.form.address = this.data.address;
      this.form.state = this.data.stateId;
      this.form.city = this.data.city.name;
      this.form.zip = this.data.zip;
      this.form.office_status = this.data.active.toString();
    }
  },
  methods: {
    onImageChange(e) {
      let files = e.target.files || e.dataTransfer.files;
      if (!files.length) return;
      this.createImage(files[0]);
    },
    createImage(file) {
      let reader = new FileReader();
      reader.onload = (e) => {
        this.form.image = e.target.result;
      };
      reader.readAsDataURL(file);
      console.log(this.form)
    },
    submitForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          office
            .create(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: false });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
    updateForm(formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          office
            .update(this.form)
            .then((response) => {
              this.$emit("refresh", { data: response, update: true });
            })
            .catch(() => {
              this.$message({
                message: this.$store.getters.message.error,
                type: "error",
                customClass: "message-error",
              });
            });
        } else {
          return false;
        }
      });
    },
  },
};
</script>

<style lang="scss">
.style-chooser .vs__search::placeholder,
.style-chooser .vs__dropdown-toggle {
  height: 45px;
}
.style-chooser .vs__dropdown-menu {
  max-height: 150px;
}
</style>

<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
